var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editor
    ? _c(
        "div",
        { staticClass: "text-editor-wrapper", class: _vm.theme },
        [
          _vm.editor && _vm.editable && _vm.bubble_menu
            ? _c(
                "bubble-menu",
                {
                  attrs: {
                    editor: _vm.editor,
                    "tippy-options": { duration: 100 },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "bubble-tool-bar" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "bubble-button",
                          class: { "is-active": _vm.editor.isActive("bold") },
                          style: { fontWeight: "bold" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleBold().run()
                            },
                          },
                        },
                        [_vm._v(" B ")]
                      ),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "button",
                        {
                          staticClass: "bubble-button",
                          class: { "is-active": _vm.editor.isActive("italic") },
                          style: { fontStyle: "italic" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleItalic().run()
                            },
                          },
                        },
                        [_vm._v(" I ")]
                      ),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "button",
                        {
                          staticClass: "bubble-button",
                          class: {
                            "is-active": _vm.editor.isActive("underline"),
                          },
                          style: { textDecoration: "underline" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleUnderline().run()
                            },
                          },
                        },
                        [_vm._v(" U ")]
                      ),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "button",
                        {
                          staticClass: "bubble-button",
                          class: { "is-active": _vm.editor.isActive("strike") },
                          style: { textDecoration: "line-through" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleStrike().run()
                            },
                          },
                        },
                        [_vm._v(" S ")]
                      ),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "button",
                        {
                          staticClass: "bubble-button",
                          class: { "is-active": _vm.editor.isActive("link") },
                          attrs: { type: "button" },
                          on: { click: _vm.setLink },
                        },
                        [_c("i", { staticClass: "el-icon-link" })]
                      ),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "button",
                        {
                          staticClass: "bubble-button",
                          class: { "is-active": _vm.editor.isActive("code") },
                          staticStyle: { width: "33px" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleCode().run()
                            },
                          },
                        },
                        [_vm._v(" </> ")]
                      ),
                      _vm.code_block
                        ? [
                            _c("div", { staticClass: "divider" }),
                            _c(
                              "button",
                              {
                                staticClass: "bubble-button",
                                class: {
                                  "is-active": _vm.editor.isActive(
                                    "code-block"
                                  ),
                                },
                                staticStyle: { width: "33px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.editor
                                      .chain()
                                      .focus()
                                      .toggleCodeBlock()
                                      .run()
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "icon-codeblock" }, [
                                  _vm._v("["),
                                  _c("span", [_vm._v("</>")]),
                                  _vm._v("]"),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
          _vm.editor && _vm.editable && _vm.floating_menu
            ? _c(
                "floating-menu",
                {
                  attrs: {
                    editor: _vm.editor,
                    "tippy-options": { duration: 100 },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "floating-tool-bar" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "floating-button",
                          class: { "is-active": _vm.editor.isActive("h1") },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 3 })
                                .run()
                            },
                          },
                        },
                        [
                          _c("h1", { staticStyle: { "font-size": "15px" } }, [
                            _vm._v("Title"),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "button",
                        {
                          staticClass: "floating-button",
                          class: { "is-active": _vm.editor.isActive("h2") },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 4 })
                                .run()
                            },
                          },
                        },
                        [
                          _c("h2", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v("Heading"),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "button",
                        {
                          staticClass: "floating-button",
                          class: { "is-active": _vm.editor.isActive("h4") },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 0 })
                                .run()
                            },
                          },
                        },
                        [
                          _c("p", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v("Body"),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "divider" }),
                      _c(
                        "button",
                        {
                          staticClass: "floating-button",
                          class: {
                            "is-active": _vm.editor.isActive("bullet-list"),
                          },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleBulletList()
                                .run()
                            },
                          },
                        },
                        [_vm._v(" ≔ ")]
                      ),
                      _c("div", {
                        staticClass: "divider",
                        staticStyle: { "margin-right": "10px" },
                      }),
                      _c(
                        "el-tooltip",
                        {
                          staticStyle: { border: "0" },
                          attrs: {
                            content: "Insert image",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                size: "mini",
                                disabled: !!_vm.progressUpload,
                              },
                              on: { click: _vm.uploadImage },
                            },
                            [
                              !_vm.progressUpload
                                ? _c("i", {
                                    staticClass:
                                      "el-icon-picture-outline-round",
                                    staticStyle: { "font-size": "18px" },
                                  })
                                : _c("el-progress", {
                                    staticStyle: {
                                      margin: "6px 0",
                                      width: "18px",
                                    },
                                    attrs: {
                                      percentage: _vm.progressUpload,
                                      "show-text": false,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("input", {
                        ref: "upload_image",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          accept: "image/jpeg, image/png",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.detectFile($event.target.files)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("editor-content", {
            directives: [
              {
                name: "shortkey",
                rawName: "v-shortkey.avoid",
                modifiers: { avoid: true },
              },
            ],
            ref: "editor_content",
            staticClass: "editor",
            attrs: { editor: _vm.editor },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }