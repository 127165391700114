var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("node-view-wrapper", [
    _c(
      "div",
      {
        staticClass: "todo-node",
        class: { selected: _vm.selected },
        attrs: { "data-name": "todo_node", "data-ref": _vm.node.attrs.ref },
      },
      [
        _vm.editing
          ? _c("todo-input", {
              ref: "input",
              attrs: {
                return_only: true,
                source: _vm.source,
                force_project_id: _vm.project_id,
              },
              on: { created: _vm.submit, cancel: () => _vm.deleteNode(true) },
            })
          : _c("todo-item", {
              ref: "todo_item",
              staticClass: "todo-item",
              attrs: {
                id: _vm.node.attrs.ref,
                item: _vm.todo,
                show_user: true,
                show_project: true,
                in_editor: true,
                editable: _vm.editable,
              },
              on: {
                saved: _vm.setTodo,
                updated: () => _vm.setCursor(true),
                cancel: () => _vm.setCursor(true),
                nav_out: _vm.setCursor,
                deleted: _vm.handleDeleteNode,
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }